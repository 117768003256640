import { Injectable } from '@angular/core';
import { CurrentUser, CurrentUserService } from '@rma/accounts/user';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { EmailLookupResponse } from '../data-access/auth-api.types';
import { OAuthApiService } from '../data-access/oauth-api.service';
import { TokenService } from '../util-token/token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly authApiService: OAuthApiService,
    private readonly userService: CurrentUserService,
    private readonly tokenService: TokenService,
  ) {}

  public login(email: string, password: string): Observable<CurrentUser | null> {
    return this.authApiService.login(email, password).pipe(switchMap(() => this.persistAuth()));
  }

  public getUserByEmail(email: string): Observable<EmailLookupResponse> {
    return this.authApiService.lookupByEmail(email);
  }

  public getUserById(userId: string): Observable<EmailLookupResponse> {
    return this.authApiService.lookupById(userId);
  }

  public loginWithLegacyToken(token: string): Observable<string | undefined> {
    return this.authApiService
      .loginWithLegacyToken(token)
      .pipe(
        switchMap((loginTokenResponse) =>
          loginTokenResponse.loginSuccessful
            ? this.persistAuth().pipe(map(() => loginTokenResponse.redirectUrl))
            : of(loginTokenResponse.redirectUrl),
        ),
      );
  }

  public logout() {
    this.userService.onLogout();
    this.tokenService.terminateToken();
  }

  public attemptRefreshToken(): Observable<boolean> {
    return this.tokenService.refreshToken().pipe(
      tap((x) => {
        if (!x) {
          this.userService.onLogout();
        }
      }),
      catchError(() => {
        this.userService.onLogout();
        return of(false);
      }),
    );
  }

  public getAccessToken() {
    const token = this.tokenService.retrieveToken();

    return token?.access_token;
  }

  private persistAuth(): Observable<CurrentUser | null> {
    return this.authApiService.authorize().pipe(
      switchMap((x) => this.authApiService.getAccessToken(x.url as string)),
      tap((x) => this.tokenService.persistToken(x)),
      switchMap(() => this.userService.reloadUser()),
    );
  }
}

import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core';
import { CurrentUserService } from '@rma/accounts/user';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const authGuard = () => {
  const document = inject(DOCUMENT);
  const environment = inject(Environment);
  const platformService = inject(PlatformService);
  const userService = inject(CurrentUserService);

  if (platformService.isPlatformServer) {
    return of(true);
  }

  return userService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      if (!isAuthenticated) {
        document.location.href = `${environment.baseUrl}/account/login?redirect=${document.location.pathname}`;
        return false;
      }
      return true;
    }),
    catchError(() => of(false)),
  );
};

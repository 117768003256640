import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment, RmaUrlsService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { AccountCreationRequest, RegisterRequest } from './auth-api.types';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private client: string;
  constructor(
    private readonly httpClient: HttpClient,
    private rmaUrl: RmaUrlsService,
    private environment: Environment,
  ) {
    this.client = this.environment.identity.client;
  }

  public requestAccountCreation(request: AccountCreationRequest): Observable<void> {
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Account/Request'), request);
  }
  public register(request: RegisterRequest): Observable<void> {
    const params = { ...request, client: this.client };
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Me/Register', 'Identity'), params);
  }

  public forgotPassword(email: string): Observable<void> {
    const params = { email, client: this.client };

    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Recovery/Password/Forgot', 'Identity'), params);
  }

  public resetPassword(request: { token: string; newPassword: string; email: string }): Observable<void> {
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Recovery/Password/Reset', 'Identity'), request);
  }

  public changeEmail(newEmail: string): Observable<void> {
    const params = { newEmail, client: this.client };
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Me/Email', 'Identity'), params);
  }

  public confirmEmailChange(request: { newEmail: string; token: string }): Observable<void> {
    const params = { ...request, client: this.client };
    return this.httpClient.put<void>(this.rmaUrl.apiUrl('Users/Me/Email', 'Identity'), params);
  }

  public updateDetails(request: { firstName: string; lastName: string }): Observable<void> {
    const params = { ...request, client: this.client };
    return this.httpClient.put<void>(this.rmaUrl.apiUrl('Users/Me', 'Identity'), params);
  }

  public changePassword(request: { oldPassword: string; newPassword: string }): Observable<void> {
    const params = { ...request, client: this.client };
    return this.httpClient.put<void>(this.rmaUrl.apiUrl('Users/Me/Password', 'Identity'), params);
  }

  public activateAccount(request: { activationCode: string }): Observable<void> {
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Account/Activate'), request);
  }

  public confirmEmail(request: { email: string; token: string }): Observable<void> {
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Me/Activate'), request);
  }

  public requestConfirmEmail(email: string): Observable<void> {
    const params = { email, client: this.client };
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Me/Activate'), params);
  }

  public confirmChangeEmail(changeEmailCode: string): Observable<string> {
    return this.httpClient.post<string>(this.rmaUrl.apiUrl('Account/ConfirmChangeEmail'), { changeEmailCode });
  }
}

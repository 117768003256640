import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';
import { FacebookLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { AuthInterceptor } from './util-auth-interceptor/auth.interceptor';
import { TokenService } from './util-token/token.service';

/**
 * this should be replaced by the provider, however it breaks authentication.
 */

export function initialiseTokenProvider(tokenService: TokenService) {
  return () => tokenService.hasAuthToken(); // todo: trigger user auth off this.
}

@NgModule({
  imports: [SocialLoginModule],
})
export class AuthenticationShellModule {
  public static forRoot(): ModuleWithProviders<AuthenticationShellModule> {
    return {
      ngModule: AuthenticationShellModule,
      providers: [
        {
          provide: 'SocialAuthServiceConfig',
          deps: [Environment],
          useFactory: (environment: Environment) =>
            ({
              autoLogin: false,
              providers: [
                {
                  id: FacebookLoginProvider.PROVIDER_ID,
                  // TODO: IDK IF THIS WORKS! I think its loaded to early !!!!!!!!!!!!!!!!!!!!!!!
                  provider: new FacebookLoginProvider(environment.social.facebookId),
                },
              ],
            }) as SocialAuthServiceConfig,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        {
          provide: APP_INITIALIZER,
          useFactory: initialiseTokenProvider,
          deps: [TokenService],
          multi: true,
        },
      ],
    };
  }
}

import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { CurrentUserService } from '@rma/accounts/user';
import { PlatformService } from '@rma/generic/util/environment';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export const newAuthGuard = () => {
  const platformService = inject(PlatformService);
  const userService = inject(CurrentUserService);
  const router = inject(Router);

  if (platformService.isPlatformServer) {
    return of(true);
  }

  return userService.isAuthenticated$.pipe(
    map((isAuthenticated) => {
      if (!isAuthenticated) {
        router.navigate(['account/login']);
        return false;
      }
      return true;
    }),
    catchError(() => of(false)),
  );
};

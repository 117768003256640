import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, Subscription, timer } from 'rxjs';
import { catchError, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';
import { IdentityToken } from '../data-access/auth-api.types';
import { OAuthApiService } from '../data-access/oauth-api.service';
import { TokenStorageService } from '../util-token-storage/token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private readonly jwtHelper: JwtHelperService;
  private refreshObservable?: Observable<boolean>;
  private refreshTimer?: Subscription;

  constructor(
    private readonly authApiService: OAuthApiService,
    private tokenStorage: TokenStorageService,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  hasAuthToken(): Observable<boolean> {
    const token = this.tokenStorage.retrieveToken();

    if (!token) {
      return of(false);
    }

    if (this.jwtHelper.isTokenExpired(token.access_token, 15)) {
      return this.handleRefresh(token).pipe(catchError(() => of(false)));
    }
    this.setupRefreshTimer(token);
    return of(true);
  }

  retrieveToken() {
    return this.tokenStorage.retrieveToken();
  }

  persistToken(token: IdentityToken) {
    this.tokenStorage.storeToken(token);
    this.setupRefreshTimer(token);
  }

  private setupRefreshTimer(token: IdentityToken) {
    if (this.refreshTimer) {
      this.refreshTimer.unsubscribe();
    }
    const expiry = this.jwtHelper.getTokenExpirationDate(token.access_token);

    if (expiry) {
      this.refreshTimer = timer(expiry)
        .pipe(switchMap(() => this.refreshToken()))
        .subscribe();
    }
  }

  refreshToken(): Observable<boolean> {
    if (this.refreshObservable) {
      return this.refreshObservable;
    }

    const token = this.tokenStorage.retrieveToken();

    if (!token) {
      return of(false);
    }

    this.refreshObservable = this.tryRefreshToken(token).pipe(
      shareReplay(1),
      tap(() => (this.refreshObservable = undefined)),
    );
    return this.refreshObservable;
  }

  private tryRefreshToken(token: IdentityToken): Observable<boolean> {
    return this.handleRefresh(token).pipe(
      catchError((e) => {
        // Maybe it was refreshed in another process/browser window, if the token has changed maybe we have "refreshed" it.
        const currentToken = this.tokenStorage.retrieveToken();

        if (currentToken && token.access_token !== currentToken.access_token) {
          return of(!this.jwtHelper.isTokenExpired(currentToken.access_token, 15));
        }
        console.error('Failed to refresh token, clearing out token', e);
        this.tokenStorage.clearToken();
        return of(false);
      }),
    );
  }

  private handleRefresh(token: IdentityToken): Observable<boolean> {
    return this.authApiService.refreshToken(token.refresh_token).pipe(
      tap((x) => this.tokenStorage.storeToken(x)),
      tap((x) => this.setupRefreshTimer(x)),
      mapTo(true),
    );
  }

  terminateToken() {
    this.tokenStorage.clearToken();
    if (this.refreshTimer) {
      this.refreshTimer.unsubscribe();
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RmaUrlsService } from '@rma/generic/util/environment';
import { Observable } from 'rxjs';
import { LoginResponse, LoginTokenResponse, OidcResponse, ResetResponse, SocialMediaChannel, UserDetails } from './auth-api.types';

@Injectable({
  providedIn: 'root',
})
export class OldAuthApiService {
  private readonly endpoints = {
    login: 'Account/Login',
    loginWithUserId: 'Account/LoginWithUserId',
    forgotPassword: 'Account/RequestPasswordReset',
    register: 'Account/register',
    loginWithProvider: 'Account/SignInWithProvider',
    logout: 'Account/Logout',
    getEmailFromResetCode: 'account/signup/email?code={code}',
    resetPassword: 'account/resetPassword',
    userDetails: '/v1/Users/Me',
    updatePassword: 'v1/Users/Me/Password',
    updateEmail: 'Account/ChangeEmail',
  };
  constructor(
    private readonly httpClient: HttpClient,
    private rmaUrl: RmaUrlsService,
  ) {}

  public login(email: string, password: string): Observable<{ userToken: string }> {
    return this.httpClient.post<{ userToken: string }>(this.rmaUrl.apiUrl(this.endpoints.login), { email, password });
  }

  public loginWithUserId(userId: string, password: string): Observable<{ userToken: string }> {
    return this.httpClient.post<{ userToken: string }>(this.rmaUrl.apiUrl(this.endpoints.loginWithUserId), {
      userId,
      password,
    });
  }

  public updateUserDetails(userDetails: UserDetails): Observable<ResetResponse> {
    return this.httpClient.post<ResetResponse>(this.rmaUrl.apiUrl(this.endpoints.userDetails), userDetails);
  }

  public updateEmail(email: string): Observable<ResetResponse> {
    return this.httpClient.post<ResetResponse>(this.rmaUrl.apiUrl(this.endpoints.updateEmail), { NewEmailAddress: email });
  }

  public getUserDetails(): Observable<UserDetails> {
    return this.httpClient.get<UserDetails>(this.rmaUrl.apiUrl(this.endpoints.userDetails));
  }

  public updatePassword(password: string, confirmPassword: string): Observable<ResetResponse> {
    const body = {
      Password: password,
      ConfirmPassword: confirmPassword,
    };
    return this.httpClient.put<ResetResponse>(this.rmaUrl.apiUrl(this.endpoints.updatePassword), body);
  }

  public loginWithProvider(authToken: string, channel: SocialMediaChannel): Observable<{ userToken: string }> {
    const body = {
      token: authToken,
      socialMediaChannelId: channel,
      acceptTermsAndConditions: true,
    };
    return this.httpClient.post<{ userToken: string }>(this.rmaUrl.apiUrl(this.endpoints.loginWithProvider), body);
  }

  public getEmailFromResetCode(code: string): Observable<string> {
    const getEmailEndpoint = this.endpoints.getEmailFromResetCode.replace('{code}', code);
    return this.httpClient.get<string>(this.rmaUrl.apiUrl(getEmailEndpoint));
  }

  public resetPassword(resetCode: string, password: string, confirmPassword: string): Observable<ResetResponse> {
    const body = {
      ResetId: resetCode,
      Password: password,
      ConfirmPassword: confirmPassword,
    };
    return this.httpClient.post<ResetResponse>(this.rmaUrl.apiUrl(this.endpoints.resetPassword), body, {
      withCredentials: true,
    });
  }

  public forgotPassword(email: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(this.rmaUrl.apiUrl(this.endpoints.forgotPassword), { email });
  }

  public register(email: string, password: string, acceptTermsAndConditions: boolean): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(this.rmaUrl.apiUrl(this.endpoints.register), {
      email,
      password,
      acceptTermsAndConditions,
    });
  }

  public logout(): Observable<void> {
    return this.httpClient.post<void>(this.rmaUrl.apiUrl(this.endpoints.logout), null);
  }

  public loginWithLegacyToken(token: string): Observable<LoginTokenResponse> {
    const params = { encryptedToken: token };
    return this.httpClient.post<LoginTokenResponse>(this.rmaUrl.apiUrl('Account/Login/Token'), params);
  }

  public logInWithOidcCode(provider: string, code: string, state: string): Observable<OidcResponse> {
    return this.httpClient.post<OidcResponse>(this.rmaUrl.apiUrl(`Account/Login/Oidc/${provider}`), { code, state });
  }
}

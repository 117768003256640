import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from '@rma/generic/util/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { AuthService } from '../feat-auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private rmaApiUrls: string[];

  constructor(
    private readonly authService: AuthService,
    environment: Environment,
  ) {
    // todo: add other frontend hittable api urls, reviews api, etc
    this.rmaApiUrls = [
      environment.api.apiUrl,
      environment.api.reviewsApiUrl,
      environment.api.socialApiUrl,
      environment.identity.url,
      environment.api.surveyApiUrl,
    ];
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.rmaApiUrls?.some((x) => request.url.startsWith(x))) {
      request = this.applyBearerToken(request);

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse, _?: Observable<HttpEvent<unknown>>) => {
          if (error.status !== 401) {
            return throwError(() => error);
          }

          return this.authService
            .attemptRefreshToken()
            .pipe(mergeMap((isRefreshed) => (isRefreshed ? next.handle(this.applyBearerToken(request)) : throwError(() => error))));
        }),
      );
    }
    return next.handle(request);
  }

  private applyBearerToken(request: HttpRequest<unknown>): HttpRequest<unknown> {
    const token = this.authService.getAccessToken();

    if (token) {
      return (request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
        withCredentials: true,
      }));
    }
    return request;
  }
}

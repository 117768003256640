import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment, RmaUrlsService } from '@rma/generic/util/environment';
import * as qs from 'qs';
import { Observable } from 'rxjs';
import { EmailLookupResponse, IdentityToken, LoginTokenResponse } from './auth-api.types';

@Injectable({
  providedIn: 'root',
})
export class OAuthApiService {
  private client: string;
  constructor(
    private readonly httpClient: HttpClient,
    private readonly rmaUrl: RmaUrlsService,
    private readonly environment: Environment,
  ) {
    this.client = this.environment.identity.client;
  }

  public login(email: string, password: string): Observable<void> {
    const params = { email, password, client: this.client };
    return this.httpClient.post<void>(this.rmaUrl.apiUrl('Users/Me/Login', 'Identity'), params, { withCredentials: true });
  }

  public loginWithLegacyToken(token: string): Observable<LoginTokenResponse> {
    const params = { encryptedToken: token, client: this.client };
    return this.httpClient.post<LoginTokenResponse>(this.rmaUrl.apiUrl('Users/Me/Login/LegacyToken', 'Identity'), params, {
      withCredentials: true,
    });
  }

  public authorize(): Observable<HttpResponse<void>> {
    const params = {
      client_id: this.environment.identity.client,
      scope: 'openid profile offline_access rma.api',
      response_type: 'code',
      redirect_uri: this.rmaUrl.apiUrl('/Users/Me/Login/Callback', 'Identity'),
    };
    return this.httpClient.get<void>(this.rmaUrl.apiUrl('/connect/authorize', 'Identity'), {
      params,
      observe: 'response',
      withCredentials: true,
    });
  }

  public getAccessToken(url: string): Observable<IdentityToken> {
    const query = qs.parse(new URL(url).search.slice(1));

    const params = new HttpParams({
      fromObject: {
        client_id: this.environment.identity.client,
        client_secret: this.environment.identity.secret,
        grant_type: 'authorization_code',
        code: query.code as string,
        redirect_uri: this.rmaUrl.apiUrl('/Users/Me/Login/Callback', 'Identity'),
      },
    });

    return this.retrieveAccessToken(params);
  }

  public refreshToken(refreshToken: string): Observable<IdentityToken> {
    const params = new HttpParams({
      fromObject: {
        client_id: this.environment.identity.client,
        client_secret: this.environment.identity.secret,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      },
    });

    return this.retrieveAccessToken(params);
  }

  public lookupByEmail(email: string): Observable<EmailLookupResponse> {
    const apiUrl = this.rmaUrl.apiUrl('/account/details');
    return this.httpClient.get<EmailLookupResponse>(apiUrl, { params: { email } });
  }

  public lookupById(userId: string): Observable<EmailLookupResponse> {
    const apiUrl = this.rmaUrl.apiUrl(`/account/${userId}/details`);
    return this.httpClient.get<EmailLookupResponse>(apiUrl);
  }

  private retrieveAccessToken(params: HttpParams): Observable<IdentityToken> {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const apiUrl = this.rmaUrl.apiUrl('/connect/token', 'Identity');

    return this.httpClient.post<IdentityToken>(apiUrl, params, { headers });
  }
}

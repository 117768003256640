import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';
import { IdentityToken } from '../data-access/auth-api.types';

const AuthStorageKey = 'rma.auth';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private token: IdentityToken;

  constructor(@Inject(LOCAL_STORAGE) private readonly localStorage: Storage) {}

  storeToken(token: IdentityToken) {
    if (this.localStorage) {
      this.localStorage.setItem(AuthStorageKey, JSON.stringify(token));
    } else {
      this.token = token;
    }
  }

  retrieveToken(): IdentityToken | null {
    if (this.localStorage) {
      const token = this.localStorage.getItem(AuthStorageKey);

      return token && JSON.parse(token);
    } else {
      return this.token;
    }
  }

  clearToken() {
    this.localStorage?.removeItem(AuthStorageKey);
  }
}

import { Injectable } from '@angular/core';
import { CookiesService } from '@ngx-utils/cookies';
import { ContextSelectorService, CurrentUser, CurrentUserService, ProfileType } from '@rma/accounts/user';
import { TokenProviderService } from '@rma/generic/util/http';
import { FacebookLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Observable, from, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LoginResponse, OidcResponse, ResetResponse, SocialMediaChannel, UserDetails } from '../data-access/auth-api.types';
import { OldAuthApiService } from '../data-access/old-auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class OldAuthService {
  constructor(
    private readonly authApiService: OldAuthApiService,
    private readonly tokenProviderService: TokenProviderService,
    private readonly userService: CurrentUserService,
    private readonly socialAuthService: SocialAuthService,
    private readonly contextSelector: ContextSelectorService,
    private readonly cookieService: CookiesService,
  ) {}

  public login(email: string, password: string): Observable<CurrentUser | null> {
    return this.authApiService.login(email, password).pipe(switchMap((x: { userToken: string }) => this.persistJwtAuth(x.userToken)));
  }

  public updateUserDetails(userDetails: UserDetails): Observable<ResetResponse> {
    return this.authApiService.updateUserDetails(userDetails);
  }

  public updateEmail(email: string): Observable<ResetResponse> {
    return this.authApiService.updateEmail(email);
  }

  public getUserDetails(): Observable<UserDetails> {
    return this.authApiService.getUserDetails();
  }

  public getEmailFromResetCode(email: string): Observable<string> {
    return this.authApiService.getEmailFromResetCode(email);
  }

  public updatePassword(password: string, confirmPassword: string): Observable<ResetResponse> {
    return this.authApiService.updatePassword(password, confirmPassword);
  }

  public resetPassword(resetCode: string, password: string, confirmPassword: string): Observable<ResetResponse> {
    return this.authApiService.resetPassword(resetCode, password, confirmPassword);
  }

  public forgotPassword(email: string): Observable<LoginResponse> {
    return this.authApiService.forgotPassword(email);
  }

  public register(email: string, password: string, acceptTermsAndConditions: boolean): Observable<LoginResponse> {
    return this.authApiService.register(email, password, acceptTermsAndConditions);
  }

  public oidcLogin(provider: string, code: string, state: string): Observable<OidcResponse> {
    return this.authApiService
      .logInWithOidcCode(provider, code, state)
      .pipe(switchMap((details) => this.persistJwtAuth(details.userToken).pipe(map(() => details))));
  }

  public facebookLogin(): Observable<CurrentUser | null> {
    return from(this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap((user: SocialUser) => this.authApiService.loginWithProvider(user.authToken, SocialMediaChannel.Facebook)),
      switchMap((x: { userToken: string }) => this.persistJwtAuth(x.userToken)),
    );
  }

  public logout(): Observable<void> {
    return this.authApiService.logout().pipe(
      tap(() => {
        this.tokenProviderService.clearUserToken();
        this.userService.onLogout();
      }),
    );
  }

  public loginWithLegacyToken(token: string): Observable<{ user: CurrentUser | null; redirectUrl: string | undefined }> {
    return this.authApiService
      .loginWithLegacyToken(token)
      .pipe(
        switchMap((x) =>
          x.loginSuccessful && x.userToken
            ? this.persistAuthAndContext(x.userToken, x.profileType, x.profileCode).pipe(
                map((y) => ({ user: y, redirectUrl: x.redirectUrl })),
              )
            : of({ user: null, redirectUrl: x.redirectUrl }),
        ),
      );
  }

  public setTokenFromCookie(): Observable<CurrentUser | null> {
    const userToken = this.cookieService.get('SamlUserToken');

    if (userToken) {
      return this.persistJwtAuth(userToken);
    }

    return of(null);
  }

  private persistAuthAndContext(
    userToken: string,
    profileType: ProfileType | undefined,
    profileCode: string | undefined,
  ): Observable<CurrentUser | null> {
    return this.persistJwtAuth(userToken).pipe(
      switchMap((x) => {
        if (profileType && profileCode) {
          if (profileType === 'Individual') {
            return this.contextSelector
              .setContext({
                type: 'Agent',
                code: profileCode,
                index: null,
              })
              .pipe(map((_) => x));
          } else {
            const agencyIndex = x?.agencies?.findIndex((agency) => agency.agencyCode === profileCode);
            return this.contextSelector
              .setContext({
                type: 'Agency',
                code: profileCode,
                index: agencyIndex ?? 0,
              })
              .pipe(map((_) => x));
          }
        } else {
          return of(x);
        }
      }),
    );
  }

  private persistJwtAuth(userToken: string): Observable<CurrentUser | null> {
    this.tokenProviderService.setUserToken(userToken);
    return this.userService.reloadUser();
  }
}
